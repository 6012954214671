<template>
  <div>
    <b-overlay
      :show="isWorking"
      :rounded="overlayConfig.rounded"
      :variant="overlayConfig.variant"
      :opacity="overlayConfig.opacity"
      :blur="overlayConfig.blur"
    >
      <template #overlay>
        <div class="mt-2">
          <div style="text-align: center">
            <span class="spinner-border " />
          </div>
          <div style="text-align: center">
            <small>{{ overlayConfig.message }}</small>
          </div>
        </div>
      </template>
      <b-card>
        <h4>
          {{ title }}
        </h4>
        <b-row>
          <b-col md="3">
            <p>
              C.U.I.T.: <b>{{ formatCuit(empleadorDto.cuit) }}</b>
            </p>
          </b-col>
          <b-col md="8">
            <p>
              Razón Social: <b>{{ empleadorDto.razonSocial }}</b>
            </p>
          </b-col>
          <b-col md="1">
            <div class="align-self-end ml-auto">
              <b-col md="12">
                <b-button
                  v-if="permisosUpd.includes(nameModulo)"
                  id="btnEditEmpleador"
                  v-b-tooltip.hover
                  variant="light"
                  title="Editar empleador"
                  size="sm"
                  @click="editEmpleador()"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
              </b-col>
            </div>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col md="12">
            <b-tabs
              content-class="mt-3"
              class="border"
            >
              <b-tab title="Datos Generales">
                <div class="mb-1 ml-1 mr-1">
                  <datos-generales
                    v-if="!isWorking"
                    :id-empleador="idEmpleador"
                    :editar-enable-disable="editar"
                    :open-mode="openMode"
                    :empleador-dto="empleadorDto"
                    :datos-generales="datosGenerales"
                  />
                </div>
              </b-tab>
              <b-tab title="Datos de Contacto">
                <div class="mb-1 ml-1 mr-1">
                  <datos-contacto
                    v-if="!isWorking"
                    :id-empleador="idEmpleador"
                    :editar-enable-disable="editar"
                    :open-mode="openMode"
                    :empleador-dto="empleadorDto"
                    :datos-contacto="DatosContacto"
                  />
                </div>
              </b-tab>
              <b-tab title="Domicilio Laboral">
                <div class="mb-1 ml-1 mr-1">
                  <domicilio-laboral
                    v-if="!isWorking"
                    :id-empleador="idEmpleador"
                    :editar-enable-disable="editar"
                    :open-mode="openMode"
                    :empleador-dto="empleadorDto"
                  />
                </div>
              </b-tab>
              <b-tab title="Documentación">
                <div class="mb-1 ml-1 mr-1">
                  <documentacion-empleador
                    :id-empleador="idEmpleador"
                    :editar-enable-disable="editar"
                    :open-mode="openMode"
                    :empleador-dto="empleadorDto"
                    :documentacion="documentacion"
                  />
                </div>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { empleadoresResource } from '@/services/empleadoresResource'
import DomicilioLaboral from './DomicilioLaboralLista.vue'
import DocumentacionEmpleador from './DocumentacionEmpleador.vue'
import DatosGenerales from './DatosGenerales.vue'
import DatosContacto from './DatosContacto.vue'

export default {
  components: {
    DomicilioLaboral,
    DocumentacionEmpleador,
    DatosGenerales,
    DatosContacto,
  },
  props: {
    idEmpleador: {
      type: String,
      required: false,
      default: undefined,
    },
    openMode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      nameModulo: 'ABM_EMPLEADORES',
      title: '',
      isWorking: false,
      editar: false,
      datosGenerales: {
        cuit: null,
        razonSocial: null,
        nombreFantasia: null,
        fechaCarga: null,
        empleadorCategoria: {
          id: null,
          nombre: null,
        },
        empleadorEstado: {
          id: null,
          nombre: null,
        },
        logo: null,
        etapaCompletadaDatosGenerales: false,
      },
      DatosContacto: {
        personaContacto: null,
        telefono: null,
        email: null,
        domicilioCalle: null,
        domicilioNumero: null,
        domicilioOficinaDepto: null,
        codigoPostal: null,
        localidad: null,
        provincia: null,
      },
      DomicilioLaboral: [],
      documentacion: [],
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.5',
        blur: '5px',
        message: '',
      },
      empleadorDto: {
        cargaCompletada: null,
        codigoPostal: null,
        cuit: null,
        domicilioCalle: null,
        domicilioNumero: null,
        domicilioOficinaDepto: null,
        email: null,
        empleadorCategoria: {
          id: null,
          nombre: null,
        },
        empleadorEstado: {
          id: null,
          nombre: null,
        },
        etapaCompletada: null,
        etapaDatosContactosCompletada: null,
        etapaDatosGeneralesCompletada: null,
        etapaDocumentacionCompletada: null,
        etapaDomicilioLaboralCompletada: null,
        fechaCarga: null,
        id: null,
        localidad: {
          id: null,
          nombre: null,
        },
        nombreFantasia: null,
        personaContacto: null,
        provincia: {
          id: null,
          nombre: null,
        },
        razonSocial: null,
        telefono: null,
      },
    }
  },
  computed: {
    isEditable() {
      return (this.openMode === 'new' || this.openMode === 'edit' || this.editarEnableDisable)
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
        user: 'currentUser',
      },
    ),
  },
  created() {
    if (this.openMode === 'edit') {
      this.title = 'Edición de Empleador'
    } else if (this.openMode === 'view') {
      this.title = 'Empleador'
    }
    this.preloadedRoles = this.$store.getters.getRoles
    this.findEmpleador(this.idEmpleador)
  },
  methods: {
    formatCuit(cuit) {
      if (cuit && cuit.length === 11) {
        return `${cuit.substring(0, 2)}-${cuit.substring(2, 10)}-${cuit.substring(10)}`
      }
      return cuit
    },
    editEmpleador() {
      if (this.editar) {
        this.editar = false
        this.title = 'Empleador'
      } else {
        this.editar = true
        this.title = 'Edición de Empleador'
      }
    },
    findEmpleador(idEmpleador) {
      this.isWorking = true
      this.overlayConfig.message = 'Recuperando datos de usuario'
      if (idEmpleador !== undefined) {
        empleadoresResource().findByIdEmpleador(idEmpleador)
          .then(resultsFindByidEmpleadores => {
            this.empleadorDto = { ...resultsFindByidEmpleadores }
            this.actualizarDatosGenerales()
            this.actualizarDatosContacto()
          }).catch(error => {
            console.error(error)
            this.$router.push('/empleadores')
          })
          .finally(() => {
            this.isWorking = false
          })
      } else {
        this.isWorking = false
      }
    },
    actualizarDatosGenerales() {
      if (this.empleadorDto !== null && Object.keys(this.empleadorDto).length !== 0) {
        this.datosGenerales.cuit = this.empleadorDto.cuit
        this.datosGenerales.razonSocial = this.empleadorDto.razonSocial
        this.datosGenerales.nombreFantasia = this.empleadorDto.nombreFantasia
        this.datosGenerales.empleadorCategoria.id = this.empleadorDto.empleadorCategoria.id
        this.datosGenerales.empleadorEstado.id = this.empleadorDto.empleadorEstado.id
        this.datosGenerales.logo = this.empleadorDto.logo
        this.datosGenerales.etapaCompletadaDatosGenerales = this.empleadorDto.etapaDatosGeneralesCompletada
        this.datosGenerales.fechaCarga = this.empleadorDto.fechaCarga
      }
    },
    actualizarDatosContacto() {
      if (this.empleadorDto !== null && Object.keys(this.empleadorDto).length !== 0) {
        this.DatosContacto.personaContacto = this.empleadorDto.personaContacto
        this.DatosContacto.telefono = this.empleadorDto.telefono
        this.DatosContacto.email = this.empleadorDto.email
        this.DatosContacto.domicilioCalle = this.empleadorDto.domicilioCalle
        this.DatosContacto.domicilioNumero = this.empleadorDto.domicilioNumero
        this.DatosContacto.domicilioOficinaDepto = this.empleadorDto.domicilioOficinaDepto
        this.DatosContacto.codigoPostal = this.empleadorDto.codigoPostal
        this.DatosContacto.localidad = {
          id: this.empleadorDto.localidad.id,
          nombre: this.empleadorDto.localidad.nombre,
        }
        this.DatosContacto.provincia = {
          id: this.empleadorDto.provincia.id,
          nombre: this.empleadorDto.provincia.nombre,
        }
      }
    },
    setPageTitle() {
      if (this.openMode === 'edit') {
        this.title = 'Edición de empleador'
      } else if (this.openMode === 'view') {
        this.title = 'Empleador'
      } else {
        this.title = 'Nuevo Empleador'
      }
    },
  },
}
</script>
